<!--
 * @Author: DY
 * @Date: 2023-01-04 20:51:57
 * @LastEditTime: 2023-01-10 17:32:44
 * @Description: 办理日志
 * @FilePath: \BusinessPlatform\src\components\businessInstance\handlingLog\index.vue
-->
<template>
    <div class="handlingLog">
        <!-- <ul>
            <template v-for="(item, index) in handlingLogData">
                <li :key="`logList_${index}`">
                    <div class="time">
                        {{ item.TransactionTime }}
                    </div>
                    <i></i>
                    <p>
                        <span>{{ item.TransactionUserName }}</span>
                        <span>{{ item.TransactionAction }}</span>
                        <span>{{ item.TransactionContent }}</span>
                    </p>
                </li>
            </template>
        </ul> -->
        <el-timeline>
            <template v-for="(item, index) in handlingLogData">
                <el-timeline-item
                    placement="top"
                    :key="`logList_${index}`"
                    :timestamp="item.TransactionTime"
                >
                    <p>
                        <span>{{ item.TransactionUserName }}</span>
                        <span>{{ item.TransactionAction }}</span>
                        <span>{{ item.TransactionContent }}</span>
                    </p>
                    <!-- <el-card>
                        <h4>更新 Github 模板</h4>
                        <p>王小虎 提交于 2018/4/12 20:46</p>
                    </el-card> -->
                </el-timeline-item>
            </template>
        </el-timeline>
    </div>
</template>

<script>
export default {
    name: 'handling-log',
    components: {},
    props: {
        currentViewData: Object,
    },
    data() {
        return {
            handlingLogData: [],
        };
    },
    watch: {},
    async created() {
        this.handlingLogData = await this.getHandlingLogData(this.currentViewData.businessInstanceId);
    },
    mounted() {},
    computed: {},
    methods: {
        getHandlingLogData(businessInstanceId) {
            const path = `/interfaceApi/basicplatform/simpleflow/businessinstance/transaction_logs/${businessInstanceId}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.handlingLog
    height: 100%
    padding: 0.2rem !important
    >>>.el-timeline
        .el-timeline-item
            .el-timeline-item__node
                width 0.08rem
                height 0.08rem
                left: 0
                background-color #D8D8D8
            .el-timeline-item__tail
                left: 0.03rem
            .el-timeline-item__wrapper
                padding-left 0.28rem
                .el-timeline-item__timestamp
                    font-size 0.12rem
                    color #999999
                .el-timeline-item__content
                    p
                        font-size: 0.14rem
                        color: #333333
                        span
                            padding-right 0.1rem
    // ul
    //     li
    //         display: flex
    //         align-items flex-start
    //         padding-bottom: 0.3rem
    //         position: relative
    //         &:hover
    //             .time,p
    //                 color #0077FA
    //             i
    //                 &:after
    //                     content ''
    //                     width 0.1rem
    //                     height 0.1rem
    //                     background: #0078FB
    //                     top 50%
    //                     left: 50%
    //                     transform: translate(-50%, -50%)
    //                     z-index 9
    //                 &:before
    //                     content ''
    //                     width 0.16rem
    //                     height 0.16rem
    //                     background: rgba(0, 120, 251, 0.3)
    //                     border-radius: 50%
    //                     position: absolute
    //                     top 50%
    //                     left: 50%
    //                     transform: translate(-50%, -50%)
    //                     z-index 8
    //         &:before
    //             content ''
    //             width 2px
    //             height 100%
    //             background: #F0F0F0
    //             position: absolute
    //             top 0.08rem
    //             left: 1.07rem
    //         &:last-child
    //             &:before
    //                 display none
    //         .time
    //             font-size: 0.12rem
    //             color #666666
    //             line-height: 0.16rem
    //             flex: 0 0 0.8rem
    //             text-align: center
    //         i
    //             flex: 0 0 0.56rem
    //             height: 0.16rem
    //             position: relative
    //             &:after
    //                 content ''
    //                 width 0.08rem
    //                 height 0.08rem
    //                 background: #D8D8D8
    //                 border-radius: 50%
    //                 position: absolute
    //                 top 50%
    //                 left: 50%
    //                 transform: translate(-50%, -50%)
    //         p
    //             font-size: 0.14rem
    //             color #333333
    //             line-height: 0.16rem
    //             span
    //                 padding-right: 0.1rem
    //                 &:last-child
    //                     padding-right: 0
</style>